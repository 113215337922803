<template>
  <div class="share">
    <headbar :title="$t('邀请好友')" bgColor="#080535"></headbar>

    <div class="content">
      <div class="qr-wrap">
        <canvas id="qrcode"></canvas>
      </div>
      <div class="address-title">{{ $t('长按保存图片') }}</div>
      <div class="url-title">{{ $t('我的邀请地址') }}</div>
      <div class="url-wrap">
        <div class="url">{{ qrCodeUrl }}</div>
      </div>
      <div class="btn" @click="copy(qrCodeUrl)">{{ $t('复制地址') }}</div>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcode'
import { ref } from 'vue';
import useClipboard from "vue-clipboard3";
import CustomComponent from "@/components/CustomComponent";

export default {
  name: '',
  components: {},
  setup() {
    return {
      us: ref(null),
      qrCodeUrl: ref("")
    }
  },
  mounted() {
    this.us = JSON.parse(this.$utils.getloc("us"))
    if (this.us) {
      this.qrCodeUrl = this.$config.send_url + "#/?reAddress=" + this.us.address
      this.createQrcode()
    }
  },
  methods: {
    copy(content) {
      let that = this;
      const { toClipboard } = useClipboard();
      try {
        toClipboard(content);
        that.$q.dialog({
          component: CustomComponent,
          componentProps: {
            messages: ["复制成功"],
            persistent: true,
          }
        });
      } catch (e) {
        console.error(e);
      }
    },
    createQrcode() {
      let canvas = document.getElementById("qrcode");
      QRCode.toCanvas(canvas, this.qrCodeUrl, { margin: 2 }, (error) => {
        if (error) {
          console.log(error);
        } else {
          //qrcode 生成的二维码会带有一些默认样式，需要调整下
          canvas.style.width = "120px";
          canvas.style.height = "120px";
        }
      });
    }
  }
}
</script>

<style scoped>
.share {
  padding: 10px;
}

.content {
  position: relative;
  min-height: calc(100vh - 70px);
  padding: 0 20px 10px;
  background-color: #332d45;
}

.qr-wrap {
  padding-top: 40px;
  display: flex;
  justify-content: center;
}

.address-title {
  margin-top: 10px;
  text-align: center;
  color: rgb(233, 109, 255);
  font-size: 15px;
}

.url-title {
  margin-top: 25px;
  text-align: center;
  color: #fff;
}

.url-wrap {
  margin-top: 10px;
  padding: 0 10px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 10px;
  background-color: #221c35;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.url {
  font-size: 18px;
  color: rgb(233, 109, 255);
}

.btn {
  margin-top: 20px;
  height: 50px;
  line-height: 50px;
  color: #fff;
  font-size: 18px;
  text-align: center;
  background: linear-gradient(90deg, #f56ef9 0, #8a6dff);
  border-radius: 10px;
}
</style>